<template>
  <div v-if="object.id">
    <div class="w-full flex flex-row items-center justify-between">
      <div class="w-8/12">
        <span class="text-indigo-600 leading-tight mb-2 text-sm cursor-pointer" @click="goback()">Ga terug</span>

        <div class="mt-12 mb-2">
          <button v-if="object.matchAllowed" @click="dissaproveMatch(object)" class="bg-red-500 hover:bg-red-700 text-white  py-1 px-3 rounded">
            Afkeuren
          </button>
          <!--
          <button v-if="object.matchAllowed === false" class="bg-green-500 hover:bg-green-700 text-white  py-1 px-3 rounded" @click="approveMatch(object)">Goedkeuren</button>
  -->
          
          <button @click="deleteMatch(object._id)" class="ml-2 bg-red-500 hover:bg-red-700 text-white  py-1 px-3 rounded">
            Verwijder
          </button>
        </div>

        <h1 class="text-3xl font-semibold">{{ object.firstname }} {{object.lastname}} met {{ object.plekje.firstname }} {{ object.plekje.lastname }}</h1>
        <p class="text-gray-600 leading-tight mt-2 text-sm"><a target="_blank" :href="env.baseUrl+'/plekjes/'+object.plekje.id">{{env.baseUrl}}/plekjes/{{object.plekje.id}}</a></p>

        <p class="block text-sm font-medium leading-5 text-gray-700 mt-5 flex flex-col">Het totaal aantal berichten in de chat: {{nrOfMessages}}</p>
        <p v-if="object.id" class="block text-sm font-medium leading-5 text-gray-700 mt-5 flex flex-col">Match id: {{object.id}}</p>
        <p v-else class="block text-sm font-medium leading-5 text-gray-700 mt-5 flex flex-col">Match id unknown, database id = {{object._id}}</p>
      </div>
      <div class="w-4/12">
        <button @click="save()" class="text-white py-2 px-4 rounded float-right ml-5 cursor-pointer" style="background-color: rgb(32, 23, 71);">Opslaan
        </button>

        <router-link :to="'/plekjes/'+object.plekje._id" class="text-white py-2 px-4 rounded float-right ml-5 cursor-pointer" style="background-color: rgb(32, 23, 71);">Bekijk plekje
        </router-link>
        <a target="_blank" :href="env.chatLink+'/'+object._id+'/d7c287b7c7a616600'" class="text-white py-2 px-4 rounded float-right cursor-pointer" style="background-color: rgb(32, 23, 71);">Open
          chat</a>
      </div>
    </div>
    <div class="w-full items-start justify-start flex flex-row">
      <div class="w-full flex flex-col border-b border-gray-300 pb-10">
        <div class="w-full mt-5 flex flex-row">
          <!--FORM-->
          <div class="w-6/12 pt-5 pr-10">
            <h3 class="text-2xl font-semibold leading-5 flex flex-row items-center">1. Basis informatie meegaan</h3>
            <div class="flex flex-row">
              <div class="w-6/12 pr-5">
                <label class="block text-sm font-medium leading-5 text-gray-700 mt-5">Voornaam</label>
                <div class="mt-2 flex rounded-md shadow-sm relative flex-row items-center">
                  <input class=" form-input flex-1 block rounded-none rounded-r-md transition duration-150 ease-in-out text-sm sm:leading-5 border-gray-300" placeholder="Voornaam" v-model="object.firstname" @change="change()">
                </div>
              </div>
              <div class="w-6/12">
                <label class="block text-sm font-medium leading-5 text-gray-700 mt-5">Achternaam</label>
                <div class="mt-2 flex rounded-md shadow-sm relative flex-row items-center">
                  <input class=" form-input flex-1 block rounded-none rounded-r-md transition duration-150 ease-in-out text-sm sm:leading-5 border-gray-300" placeholder="Achternaam" v-model="object.lastname" @change="change()">
                </div>
              </div>
            </div>
            <div class="flex flex-row">
              <div class="w-6/12 pr-5">
                <label class="block text-sm font-medium leading-5 text-gray-700 mt-5">Email</label>
                <div class="mt-2 flex rounded-md shadow-sm relative flex-row items-center">
                  <input class=" form-input flex-1 block rounded-none rounded-r-md transition duration-150 ease-in-out text-sm sm:leading-5 border-gray-300" placeholder="Email" v-model="object.email" @change="change()">
                </div>
              </div>
              <div class="w-6/12">
                <label class="block text-sm font-medium leading-5 text-gray-700 mt-5">Telefoon</label>
                <div class="mt-2 flex rounded-md shadow-sm relative flex-row items-center">
                  <input class=" form-input flex-1 block rounded-none rounded-r-md transition duration-150 ease-in-out text-sm sm:leading-5 border-gray-300" placeholder="Telefoon" v-model="object.phone" @change="change()">
                </div>
              </div>
            </div>

            <div class="flex flex-row">
              <div class="w-6/12 pr-5">
                <label class="block text-sm font-medium leading-5 text-gray-700 mt-5">Geboortejaar</label>
                <div class="mt-2 flex rounded-md shadow-sm relative flex-row items-center">
                  <input class=" form-input flex-1 block rounded-none rounded-r-md transition duration-150 ease-in-out text-sm sm:leading-5 border-gray-300" placeholder="Geboortejaar" v-model="object.birthYear" @change="change()">
                </div>
              </div>
              <div class="w-6/12">
                <label class="block text-sm font-medium leading-5 text-gray-700 mt-5">Zip</label>
                <div class="mt-2 flex rounded-md shadow-sm relative flex-row items-center">
                  <input class=" form-input flex-1 block rounded-none rounded-r-md transition duration-150 ease-in-out text-sm sm:leading-5 border-gray-300" placeholder="Zip" v-model="object.zip" @change="change()">
                </div>
              </div>
            </div>

            <div class="flex flex-row">
              <div class="w-6/12 pr-5">
                <label class="block text-sm font-medium leading-5 text-gray-700 mt-5">Geslacht</label>
                <div class="mt-2 flex rounded-md shadow-sm relative flex-row items-center">
                  <select v-model="object.gender" class="form-input flex-1 block w-full rounded-none rounded-r-md transition duration-150 ease-in-out text-sm sm:leading-5 border-gray-300" placeholder="" @change="change()">
                    <option value="male">Man</option>
                    <option value="female">Vrouw</option>
                    <option value="other">Anders</option>
                  </select>
                  <svg class="w-6 h-6 absolute right-0 mr-3" version="1.1" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                    <g fill="none">
                      <path d="M0,0h24v24h-24Z"></path>
                      <path stroke="#323232" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M8,10l4,4l4,-4"></path>
                    </g>
                  </svg>
                </div>
              </div>
              <div class="w-6/12">
                <label class="block text-sm font-medium leading-5 text-gray-700 mt-5">Beperking</label>
                <div class="mt-2 flex rounded-md shadow-sm relative flex-row items-center">
                  <select v-model="object.beperking" class="form-input flex-1 block w-full rounded-none rounded-r-md transition duration-150 ease-in-out text-sm sm:leading-5 border-gray-300" placeholder="België" @change="change()">
                    <option value="true">Ja</option>
                    <option value="false">Nee</option>
                  </select>
                  <svg class="w-6 h-6 absolute right-0 mr-3" version="1.1" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                    <g fill="none">
                      <path d="M0,0h24v24h-24Z"></path>
                      <path stroke="#323232" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M8,10l4,4l4,-4"></path>
                    </g>
                  </svg>
                </div>
              </div>
            </div>

            <div class="flex flex-row">
              <div class="w-6/12 pr-5">
                <label class="block text-sm font-medium leading-5 text-gray-700 mt-5">NewsGen</label>
                <div class="mt-2 flex rounded-md shadow-sm relative flex-row items-center">
                  <select v-model="object.newsGen" class="form-input flex-1 block w-full rounded-none rounded-r-md transition duration-150 ease-in-out text-sm sm:leading-5 border-gray-300" placeholder="België" @change="change()">
                    <option :value="true">Ja</option>
                    <option :value="undefined">Nee</option>
                  </select>
                  <svg class="w-6 h-6 absolute right-0 mr-3" version="1.1" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                    <g fill="none">
                      <path d="M0,0h24v24h-24Z"></path>
                      <path stroke="#323232" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M8,10l4,4l4,-4"></path>
                    </g>
                  </svg>
                </div>
              </div>
              <div class="w-6/12">
                <label class="block text-sm font-medium leading-5 text-gray-700 mt-5">Image URL</label>
                <div class="mt-2 flex rounded-md shadow-sm relative flex-row items-center">
                  <input class=" form-input flex-1 block rounded-none rounded-r-md transition duration-150 ease-in-out text-sm sm:leading-5 border-gray-300" placeholder="Image URL" v-model="object.image" @change="change()">
                </div>
              </div>
            </div>
            <label class="block text-sm font-medium leading-5 text-gray-700 mt-5"><b id="TchhVrmOt" required="">Voeg hier een foto toe</b>
            </label>
            <div class="col-lg-4" id="grgQHJgpY">
              <img width="150" height="150" id="output" onclick="document.getElementById('upload_image_input').click();" style="cursor: pointer" src="https://cdn.circuitsortie.be/fotos/150.png"><br id="znbLToREc">
              <input type="file" accept="image/*" @change="uploadFiles" id="upload_image_input" name="file" style="display: none;" multiple>
              <p v-if="uploaded">Bestand is geüpload</p>
            </div>
            <h3 class="text-xl font-semibold leading-5 flex flex-row items-center mt-10">1.2. Begeleider</h3>

            <div class="flex flex-row">
              <div class="w-6/12 pr-5">
                <label class="block text-sm font-medium leading-5 text-gray-700 mt-5">Voornaam
                  begeleider</label>
                <div class="mt-2 flex rounded-md shadow-sm relative flex-row items-center">
                  <input class=" form-input flex-1 block rounded-none rounded-r-md transition duration-150 ease-in-out text-sm sm:leading-5 border-gray-300" placeholder="Voornaam begeleider" v-model="object.firstname_begeleider" @change="change()">
                </div>
              </div>
              <div class="w-6/12">
                <label class="block text-sm font-medium leading-5 text-gray-700 mt-5">Achternaam
                  begeleider</label>
                <div class="mt-2 flex rounded-md shadow-sm relative flex-row items-center">
                  <input class=" form-input flex-1 block rounded-none rounded-r-md transition duration-150 ease-in-out text-sm sm:leading-5 border-gray-300" placeholder="Achternaam begeleider" v-model="object.lastname_begeleider" @change="change()">
                </div>
              </div>
            </div>

            <div class="flex flex-row">
              <div class="w-6/12 pr-5">
                <label class="block text-sm font-medium leading-5 text-gray-700 mt-5">Email
                  begeleider</label>
                <div class="mt-2 flex rounded-md shadow-sm relative flex-row items-center">
                  <input class=" form-input flex-1 block rounded-none rounded-r-md transition duration-150 ease-in-out text-sm sm:leading-5 border-gray-300" placeholder="Email begeleider" v-model="object.email_begeleider" @change="change()">
                </div>
              </div>
              <div class="w-6/12">
                <label class="block text-sm font-medium leading-5 text-gray-700 mt-5">Telefoon
                  begeleider</label>
                <div class="mt-2 flex rounded-md shadow-sm relative flex-row items-center">
                  <input class=" form-input flex-1 block rounded-none rounded-r-md transition duration-150 ease-in-out text-sm sm:leading-5 border-gray-300" placeholder="Telefoon begeleider" v-model="object.phone_begeleider" @change="change()">
                </div>
              </div>
            </div>

            <h3 class="mt-5 text-xl font-semibold leading-5 flex flex-row items-center">1.3. Bijhorende
              afbeelding</h3>
            <div class="m-5 p-5 h-96 w-96" :style="`background-image: url('${object.image}'); background-position: center; background-size: cover; background-repeat: no-repeat;`">
            </div>
            <h3 class="mt-10 text-xl font-semibold leading-5 flex flex-row items-center">1.4. Beperking</h3>
            <label class="block text-sm font-medium leading-5 text-gray-700 mt-5">Soort beperking</label>
            <div class="mt-2 flex rounded-md shadow-sm relative flex-row items-center">
              <input rows="3" class=" form-input flex-1 block rounded-none rounded-r-md transition duration-150 ease-in-out text-sm sm:leading-5 border-gray-300" placeholder="Soort beperking" v-model="object.beperking_type" @change="change()" />
            </div>
            <label class="block text-sm font-medium leading-5 text-gray-700 mt-5">Hulp</label>
            <div class="mt-2 flex rounded-md shadow-sm relative flex-row items-center">
              <select v-model="object.hulp" class="form-input flex-1 block rounded-none rounded-r-md transition duration-150 ease-in-out text-sm sm:leading-5 border-gray-300">
                <option value="">Selecteer optie</option>
                <option value="Rolstoel duwen">Rolstoel duwen</option>
                <option value="Bij het eten">Bij het eten</option>
                <option value="Bij het drinken">Bij het drinken</option>
                <option value="Naar het toilet gaan">Naar het toilet gaan</option>
                <option value="Ik ben blind/slechtziend">Ik ben blind/slecthziend</option>
                <option value="Ik ben doof/slechthorend">Ik ben doof/slechthorend</option>
                <option value="Ik heb speciaal vervoer nodig">Ik heb speciaal vervoer nodig</option>
                <option value="Andere">Andere</option>
              </select>
            </div>
            <label class="block text-sm font-medium leading-5 text-gray-700 mt-5">Hulp notes</label>
            <div class="mt-2 flex rounded-md shadow-sm relative flex-row items-center">
              <textarea rows="3" class=" form-input flex-1 block rounded-none rounded-r-md transition duration-150 ease-in-out text-sm sm:leading-5 border-gray-300" placeholder="Hulp notities" v-model="object.hulpNotes" @change="change()"></textarea>
            </div>

            <h3 class="text-xl font-semibold leading-5 flex flex-row items-center mt-10">1.5. Notities</h3>
            <label class="block text-sm font-medium leading-5 text-gray-700 mt-5">Opmerkingen</label>
            <div class="mt-2 flex rounded-md shadow-sm relative flex-row items-center">
              <textarea rows="3" class=" form-input flex-1 block rounded-none rounded-r-md transition duration-150 ease-in-out text-sm sm:leading-5 border-gray-300" placeholder="Extra opmerkingen" v-model="object.other" @change="change()"></textarea>
            </div>
            <h3 class="text-xl font-semibold leading-5 flex flex-row items-center mt-10">1.6. Interne Opmerkingen</h3>

            <label class="block text-sm font-medium leading-5 text-gray-700 mt-5">Interne opmerkingen</label>
            <div class="mt-2 flex rounded-md shadow-sm relative flex-row items-center">
              <textarea rows="3" class=" form-input flex-1 block rounded-none rounded-r-md transition duration-150 ease-in-out text-sm sm:leading-5 border-gray-300" placeholder="Extra opmerkingen" v-model="object.internalremarks" @change="change()"></textarea>
            </div>

          </div>
          <div class="w-6/12 pt-5 pr-10">
            <h3 class="text-2xl font-semibold leading-5 flex flex-row items-center">2. Basis informatie eigenaar plekje</h3>
            <div class="flex flex-row">
              <div class="w-6/12 pr-5">
                <label class="block text-sm font-medium leading-5 text-gray-700 mt-5">Voornaam</label>
                <div class="mt-2 flex rounded-md shadow-sm relative flex-row items-center">
                  <input class=" form-input flex-1 block rounded-none rounded-r-md transition duration-150 ease-in-out text-sm sm:leading-5 border-gray-300" placeholder="Voornaam" v-model="object.plekje.firstname" @change="change()">
                </div>
              </div>
              <div class="w-6/12">
                <label class="block text-sm font-medium leading-5 text-gray-700 mt-5">Achternaam</label>
                <div class="mt-2 flex rounded-md shadow-sm relative flex-row items-center">
                  <input class=" form-input flex-1 block rounded-none rounded-r-md transition duration-150 ease-in-out text-sm sm:leading-5 border-gray-300" placeholder="Achternaam" v-model="object.plekje.lastname" @change="change()">
                </div>
              </div>
            </div>
            <div class="flex flex-row">
              <div class="w-6/12 pr-5">
                <label class="block text-sm font-medium leading-5 text-gray-700 mt-5">Email</label>
                <div class="mt-2 flex rounded-md shadow-sm relative flex-row items-center">
                  <input class=" form-input flex-1 block rounded-none rounded-r-md transition duration-150 ease-in-out text-sm sm:leading-5 border-gray-300" placeholder="Email" v-model="object.plekje.owner" @change="change()">
                </div>
              </div>
              <div class="w-6/12">
                <label class="block text-sm font-medium leading-5 text-gray-700 mt-5">Telefoon</label>
                <div class="mt-2 flex rounded-md shadow-sm relative flex-row items-center">
                  <input class=" form-input flex-1 block rounded-none rounded-r-md transition duration-150 ease-in-out text-sm sm:leading-5 border-gray-300" placeholder="Telefoon" v-model="object.plekje.phone" @change="change()">
                </div>
              </div>
            </div>
            <div class="flex flex-row">
              <div class="w-6/12 pr-5">
                <label class="block text-sm font-medium leading-5 text-gray-700 mt-5">Geboortejaar</label>
                <div class="mt-2 flex rounded-md shadow-sm relative flex-row items-center">
                  <input class=" form-input flex-1 block rounded-none rounded-r-md transition duration-150 ease-in-out text-sm sm:leading-5 border-gray-300" placeholder="Geboortejaar" v-model="object.plekje.birthYear" @change="change()">
                </div>
              </div>
              <div class="w-6/12">
                <label class="block text-sm font-medium leading-5 text-gray-700 mt-5">Geslacht</label>
                <div class="mt-2 flex rounded-md shadow-sm relative flex-row items-center">
                  <select v-model="object.plekje.gender" class="form-input flex-1 block w-full rounded-none rounded-r-md transition duration-150 ease-in-out text-sm sm:leading-5 border-gray-300" placeholder="Geslacht" @change="change()">
                    <option value="male">Man</option>
                    <option value="female">Vrouw</option>
                    <option value="">Anders</option>
                  </select>
                  <svg class="w-6 h-6 absolute right-0 mr-3" version="1.1" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                    <g fill="none">
                      <path d="M0,0h24v24h-24Z"></path>
                      <path stroke="#323232" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M8,10l4,4l4,-4"></path>
                    </g>
                  </svg>
                </div>
              </div>
            </div>
            <div class="flex flex-row">
              <div class="w-6/12 pr-5">
                <label class="block text-sm font-medium leading-5 text-gray-700 mt-5">Zip</label>
                <div class="mt-2 flex rounded-md shadow-sm relative flex-row items-center">
                  <input class=" form-input flex-1 block rounded-none rounded-r-md transition duration-150 ease-in-out text-sm sm:leading-5 border-gray-300" placeholder="Email" v-model="object.plekje.zip" @change="change()">
                </div>
              </div>
              <div class="w-6/12">
                <label class="block text-sm font-medium leading-5 text-gray-700 mt-5">Beperking</label>
                <div class="mt-2 flex rounded-md shadow-sm relative flex-row items-center">
                  <select v-model="object.plekje.beperking" class="form-input flex-1 block w-full rounded-none rounded-r-md transition duration-150 ease-in-out text-sm sm:leading-5 border-gray-300" placeholder="België" @change="change()">
                    <option value="true">Ja</option>
                    <option value="false">Nee</option>
                  </select>
                  <svg class="w-6 h-6 absolute right-0 mr-3" version="1.1" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                    <g fill="none">
                      <path d="M0,0h24v24h-24Z"></path>
                      <path stroke="#323232" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M8,10l4,4l4,-4"></path>
                    </g>
                  </svg>
                </div>
              </div>
            </div>
            <div class="flex flex-row">
              <div class="w-6/12 pr-5">
                <label class="block text-sm font-medium leading-5 text-gray-700 mt-5">Hulp</label>
                <div class="mt-2 flex rounded-md shadow-sm relative flex-row items-center">
                  <input class=" form-input flex-1 block rounded-none rounded-r-md transition duration-150 ease-in-out text-sm sm:leading-5 border-gray-300" placeholder="Email" v-model="object.plekje.hulp" @change="change()">
                </div>
              </div>
              <div class="w-6/12">
                <label class="block text-sm font-medium leading-5 text-gray-700 mt-5">Notities hulp</label>
                <div class="mt-2 flex rounded-md shadow-sm relative flex-row items-center">
                  <input class=" form-input flex-1 block rounded-none rounded-r-md transition duration-150 ease-in-out text-sm sm:leading-5 border-gray-300" placeholder="Telefoon" v-model="object.plekje.hulpNotes" @change="change()">
                </div>
              </div>
            </div>
            <div class="flex flex-row">
              <div class="w-6/12 pr-5">
                <label class="block text-sm font-medium leading-5 text-gray-700 mt-5">Voornaam begeleider</label>
                <div class="mt-2 flex rounded-md shadow-sm relative flex-row items-center">
                  <input class=" form-input flex-1 block rounded-none rounded-r-md transition duration-150 ease-in-out text-sm sm:leading-5 border-gray-300" placeholder="Email" v-model="object.plekje.firstname_begeleider" @change="change()">
                </div>
              </div>
              <div class="w-6/12">
                <label class="block text-sm font-medium leading-5 text-gray-700 mt-5">Achternaam begeleider</label>
                <div class="mt-2 flex rounded-md shadow-sm relative flex-row items-center">
                  <input class=" form-input flex-1 block rounded-none rounded-r-md transition duration-150 ease-in-out text-sm sm:leading-5 border-gray-300" placeholder="Telefoon" v-model="object.plekje.lastname_begeleider" @change="change()">
                </div>
              </div>
            </div>
            <label v-if="false" class="block text-sm font-medium leading-5 text-gray-700 mt-5">Plekje ID</label>
            <div v-if="false" class="mt-2 flex rounded-md shadow-sm relative flex-row items-center">
              <input class="form-input flex-1 block rounded-none rounded-r-md transition duration-150 ease-in-out text-sm sm:leading-5 border-gray-300" placeholder="Plekje ID" v-model="object.plekjeId" @change="change()">
            </div>
            <label class="block text-sm font-medium leading-5 text-gray-700 mt-5">Wat</label>
            <div class="mt-2 flex rounded-md shadow-sm relative flex-row items-center">
              <input class="form-input flex-1 block rounded-none rounded-r-md transition duration-150 ease-in-out text-sm sm:leading-5 border-gray-300" placeholder="Plekje ID" v-model="object.plekje.what" @change="change()">
            </div>


            <label class="block text-sm font-medium leading-5 text-gray-700 mt-5">Waar</label>
            <div v-if="object.plekje.type == 'festival' && festival">
              {{festival.name}} op {{object.plekje.festivalDate}}
            </div>
            <div v-else>
            <div class="mt-2 flex rounded-md shadow-sm relative flex-row items-center">
              <input class="form-input flex-1 block rounded-none rounded-r-md transition duration-150 ease-in-out text-sm sm:leading-5 border-gray-300" placeholder="Where" v-model="object.plekje.where" @change="change()" />
            </div>


            <label class="block text-sm font-medium leading-5 text-gray-700 mt-5">Waarom</label>
            <div class="mt-2 flex rounded-md shadow-sm relative flex-row items-center">
              <input class="form-input flex-1 block rounded-none rounded-r-md transition duration-150 ease-in-out text-sm sm:leading-5 border-gray-300" placeholder="Plekje ID" v-model="object.plekje.why" @change="change()">
            </div>
            <div class="flex flex-row">
              <div class="w-6/12 pr-5">
                <label class="block text-sm font-medium leading-5 text-gray-700 mt-5">Wanneer (format: dd/mm/jjjj)</label>
                <div class="mt-2 flex rounded-md shadow-sm relative flex-row items-center">
                  <input class=" form-input flex-1 block rounded-none rounded-r-md transition duration-150 ease-in-out text-sm sm:leading-5 border-gray-300" placeholder="datum" :value="object.datum_activiteit" @change="change()">
                </div>
              </div>
              <div class="w-6/12">
                <label class="block text-sm font-medium leading-5 text-gray-700 mt-5">Datum notitie</label>
                <div class="mt-2 flex rounded-md shadow-sm relative flex-row items-center">
                  <input class=" form-input flex-1 block rounded-none rounded-r-md transition duration-150 ease-in-out text-sm sm:leading-5 border-gray-300" placeholder="info over datum" v-model="object.datum_activiteit_notitie" @change="change()">
                </div>
              </div>
            </div>
            <div>
              <h3 class="mt-5 text-xl font-semibold leading-5 flex flex-row items-center">2.2 Bijhorende
                afbeelding</h3>
              <div class="m-5 p-5 h-96 w-96" :style="`background-image: url('${object.plekje.image}'); background-position: center; background-size: cover; background-repeat: no-repeat;`">
              </div>
            </div>
            <h3 class="mt-10 text-2xl font-semibold leading-5 flex flex-row items-center">3. Informatie match</h3>
            <div class="flex flex-row">
              <div class="w-6/12 pr-5">
                <label class="block text-sm font-medium leading-5 text-gray-700 mt-5">Check
                  needs</label>
                <div class="mt-2 flex rounded-md shadow-sm relative flex-row items-center">
                  <input class=" form-input flex-1 block rounded-none rounded-r-md transition duration-150 ease-in-out text-sm sm:leading-5 border-gray-300" placeholder="true/false" v-model="object.check_needs" @change="change()">
                </div>
              </div>
              <div class="w-6/12">
                <label class="block text-sm font-medium leading-5 text-gray-700 mt-5">Transport in
                  orde?</label>
                <div class="mt-2 flex rounded-md shadow-sm relative flex-row items-center">
                  <input class=" form-input flex-1 block rounded-none rounded-r-md transition duration-150 ease-in-out text-sm sm:leading-5 border-gray-300" placeholder="true/false" v-model="object.check_transport" @change="change()">
                </div>
              </div>
            </div>
            <div class="flex flex-row">
              <div class="w-6/12 pr-5">
                <label class="block text-sm font-medium leading-5 text-gray-700 mt-5">Heb je duidelijk afgesproken wat je plannen zijn?</label>
                <div class="mt-2 flex rounded-md shadow-sm relative flex-row items-center">
                  <input class=" form-input flex-1 block rounded-none rounded-r-md transition duration-150 ease-in-out text-sm sm:leading-5 border-gray-300" placeholder="true/false" v-model="object.check_what" @change="change()">
                </div>
              </div>
              <div class="w-6/12">
                <label class="block text-sm font-medium leading-5 text-gray-700 mt-5">Datum
                  afgesproken?</label>
                <div class="mt-2 flex rounded-md shadow-sm relative flex-row items-center">
                  <input class=" form-input flex-1 block rounded-none rounded-r-md transition duration-150 ease-in-out text-sm sm:leading-5 border-gray-300" placeholder="true/false" v-model="object.check_when" @change="change()">
                </div>
              </div>
            </div>
            <div class="flex flex-row">
              <div class="w-6/12 pr-5">
                <!-- ADD -->
                <label class="block text-sm font-medium leading-5 text-gray-700 mt-5">Is iedereen aan het chatten?</label>
                <div class="mt-2 flex rounded-md shadow-sm relative flex-row items-center">
                  <input class=" form-input flex-1 block rounded-none rounded-r-md transition duration-150 ease-in-out text-sm sm:leading-5 border-gray-300" placeholder="Achternaam" v-model="object.chatting" @change="change()">
                </div>
              </div>
              <div class="w-6/12">
                <label class="block text-sm font-medium leading-5 text-gray-700 mt-5">Automatische introductie mail match verzonden?</label>
                <div class="mt-2 flex rounded-md shadow-sm relative flex-row items-center">
                  <input class=" form-input flex-1 block rounded-none rounded-r-md transition duration-150 ease-in-out text-sm sm:leading-5 border-gray-300" placeholder="true/false" v-model="object.automaticIntroMailSent" @change="change()">
                </div>
              </div>
            </div>
            <h3 class="text-xl font-semibold leading-5 flex flex-row items-center mt-10">3.1. Chat links</h3>
            <p class="block text-sm font-medium leading-5 text-gray-700 mt-5 flex flex-col">
              <span class="mt-2">Chat link admin: <a :href="env.chatLink+'/'+object._id+'/d7c287b7c7a616600'" target="_blank">{{env.chatLink}}/{{object._id}}/d7c287b7c7a616600</a></span>
              <span class="mt-2">Chat link plekje: <a :href="env.chatLink+'/'+object._id+'/d7c287b7c7a61666f'" target="_blank">{{env.chatLink}}/{{object._id}}/d7c287b7c7a61666f</a></span>
              <span class="mt-2">Chat link match: <a :href="env.chatLink+'/'+object._id+'/7c606d287b6d6b7a6'" target="_blank">{{env.chatLink}}/{{object._id}}/7c606d287b6d6b7a6</a></span>
            </p>



            <div>
            <h3 class="text-2xl font-semibold leading-5 flex flex-row items-center mt-10">
              10. developer information
            </h3>
            <div class="flex flex-row">
              <div v-if="true" class="w-6/12">
                <label class="block text-sm font-medium leading-5 text-gray-700 mt-5">Match _id: Developer info, niet aanpassen aub
                </label>
                <div class="mt-2 flex rounded-md shadow-sm relative flex-row items-center">
                  <input class="form-input flex-1 block rounded-none rounded-r-md transition duration-150 ease-in-out text-sm sm:leading-5 border-gray-300" placeholder="Match id" v-model="object.plekjeId" />
                </div>
              </div>



            </div>
          </div>


          </div>
        </div>
      </div>

    </div>
  </div>
</template>


<script>
import env from "@/../env.js";
import Cookies from "@/helpers/cookies";
import API from '@/helpers/api'

const axios = require("axios");

export default {
  name: "home",
  data: function() {
    return {
      object: {},
      env,
      nrOfMessages: 0,
      festivals: [],
      festival: false,
      uploaded: false
    };
  },
  methods: {
    updateFestival: function(){
      console.log('update Festivals')
      if(this.object.plekje && this.object.plekje.festival){
        console.log('check 1')
        for (var i = 0; i < this.festivals.length; i++) {
          if(this.festivals[i]._id.toString() == (this.object.plekje.festival || '').toString()){
            this.festival = this.festivals[i];
          } else {
            console.log('No match:', this.festivals[i]._id.toString(), (this.object.plekje.festival || '').toString())
          }
        }
      }
    },
    add: function() {
      this.object = {};
      this.popup = true;
    },
    load: async function() {


      const { data } = await axios.get(
        `${env.apiURL}/matches/${this.$route.params.id}`,
        {
          headers: { Authorization: "Bearer " + Cookies.get("session") }
        }
      );
      console.log("loaded:", data);
      this.object = data;

      const festivals = await API.get("/festivals");
      this.festivals = festivals.data;
      this.updateFestival();

      this.getTotalMessages({
        candidateEmail: data.email,
        ownerMail: data.plekje.owner
      });
    },
    approveMatch: function(match) {
      const _self = this;
      match.checked = true;
      match.matchAllowed = true;
      axios
        .put(env.apiURL + "/matches/" + match._id, match, {
          headers: {
            Authorization: "Bearer " + Cookies.get("session")
          }
        })
        .then(function() {
          _self.object.plekje.hasMatch = true;
          _self.object.plekje.active = false;
          _self.object.plekje.matchId = match._id;
          axios
            .put(
              env.apiURL + "/plekjes/" + _self.object.plekje._id,
              _self.object.plekje,
              {
                headers: {
                  Authorization: "Bearer " + Cookies.get("session")
                }
              }
            )
            .then(function(res) {
              console.log(res.data);
              _self.load();
              _self.changesButNotSaved = false;
              alert("Saved");
            });
        });
    },
    dissaproveMatch: function(match) {
      const _self = this;
      match.checked = true;
      match.matchAllowed = false;
      match.plekje.active = true;
      match.plekje.hasMatch = false;
      match.plekje.hasReply = false;
      console.log(match);
      axios
        .put(env.apiURL + "/matches/" + match._id, match, {
          headers: {
            Authorization: "Bearer " + Cookies.get("session")
          }
        })
        .then(function(res) {
          console.log(res);
        });
      axios
        .put(env.apiURL + "/plekjes/" + match.plekje._id, match.plekje, {
          headers: {
            Authorization: "Bearer " + Cookies.get("session")
          }
        })
        .then(function() {
          _self.popup = false;
          _self.changesButNotSaved = false;
          alert("Match ongedaan gemaakt");
        });
    },
    copyMatch: function(match) {
      const _self = this;
      axios
        .post(env.apiURL + "/matches", match, {
          headers: {
            Authorization: "Bearer " + Cookies.get("session")
          }
        })
        .then(function(res) {
          _self.data.unshift(res.data);
          _self.popup = false;
          alert("Aangemaakt");
        });
    },
    deleteMatch: function(matchId) {
      const _self = this;
      if (
        confirm(
          "Ben je zeker dat je deze match wilt verwijderen? Je kan deze actie niet ongedaan maken"
        )
      ) {
        axios
          .delete(env.apiURL + "/matches/" + matchId, {
            headers: {
              Authorization: "Bearer " + Cookies.get("session")
            }
          })
          .then(function() {
            alert("Deleted");
            _self.$router.go(-1);
          });
      }
    },
    save: function() {
      console.log(this.object.birthYear);
      console.log(this.object.plekje.birthYear);
      axios
        .put(env.apiURL + "/matches/" + this.object._id, this.object, {
          headers: {
            Authorization: "Bearer " + Cookies.get("session")
          }
        })
        .then(function() {
          alert("Saved");
        });
    },
    open: function() {
      const url = env.apiURL + "/matches/" + this.object._id;
      document.location = url;
    },
    remove: function() {
      if (confirm("Are you sure? This action cannot be undone!!")) {
        axios
          .delete(env.apiURL + "/matches/" + this.object._id, {
            headers: {
              Authorization: "Bearer " + Cookies.get("session")
            }
          })
          .then(function() {
            document.location = "/";
          });
      }
    },
    sendFile: function(formData) {
      const _self = this;
      return new Promise(function(resolve) {
        fetch(env.apiURL + "/files/upload/match", {
          method: "POST",
          body: formData
        })
          .then(res => res.json())
          .then(res => {
            console.log("Done uploading", res);
            resolve(res.file);
            _self.object.image = res.file;
            console.log(_self.object.image);
          })
          .catch(e => {
            console.error(JSON.stringify(e.message));
            resolve(false);
          });
      });
    },
    uploadFiles: async function(e) {
      let droppedFiles = e.target.files[0];
      console.log(droppedFiles);
      if (!droppedFiles) return;
      if (droppedFiles.length < 1) return;
      let formData = new FormData();
      formData.append("file", droppedFiles);
      await this.sendFile(formData);
      this.uploaded = true;
      this.change();
    },
    getTotalMessages: function(sendBody) {
      const _self = this;
      axios
        .post(env.apiURL + "/messages/MatchMessages", sendBody, {
          headers: {
            Authorization: "Bearer " + Cookies.get("session")
          }
        })
        .then(function(res) {
          _self.nrOfMessages = res.data;
        });
    },
    formatBirthYear: function(date) {
      const newDate = new Date(date);
      console.log(newDate);
      return newDate.getUTCFullYear();
    },
    formatDate: function(date) {
      console.log("DATE");
      console.log(date);
      if (date) {
        const newDate = new Date(date);
        const month = newDate.getMonth() + 1;
        return (
          newDate.getUTCDate() +
          1 +
          "/" +
          month +
          "/" +
          newDate.getUTCFullYear()
        );
      }
    },
    goback: function() {
      const _self = this;
      console.log(_self.changed);
      if (!_self.changed) {
        this.$router.go(-1);
      } else {
        const c = confirm(
          "Weet u zeker dat u de pagina wilt verlaten? Uw wijzigingen zijn nog niet opgeslagen"
        );
        if (c === true) {
          this.$router.go(-1);
        }
      }
    },
    change: function() {
      const _self = this;
      _self.changed = true;
      console.log(_self.changed);
    }
  },
  async mounted() {
    await this.load();
  }
};
</script>

<style>
.form-input {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: #fff;
  border-width: 1px;
  border-radius: 0.375rem;
  padding: 0.5rem 0.75rem;
  line-height: 1.5;
}

.form-input:focus {
  outline: none;
  box-shadow: 0 0 0 3px rgba(164, 202, 254, 0.45);
  border-color: #a4cafe;
}
</style>
